import React from 'react';
import './index.css';
import {FaFacebookSquare,FaTwitterSquare,FaLinkedin,FaYoutubeSquare} from "react-icons/fa";

function Footer() {
    return (
        <footer id="main-footer">
            <div className="container">
                <div className="elementor-widget-wrap elementor-element-populated">
                    <section className="logo-white">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-7ff397b elementor-widget elementor-widget-image" data-id="7ff397b" data-element_type="widget" data-widget_type="image.default">
                                        <div className="elementor-widget-container">
                                            <a href="https://www.directics.com">
                                                <img src="https://www.directics.com/wp-content/uploads/2022/11/icon.svg" alt="" loading="lazy" width="110" height="40" />
                                            </a>
                                </div>
                            </div>
                        </div>

                    </section>
                    <section className="elementor-section elementor-inner-section elementor-element elementor-element-e8616e9 elementor-section-full_width elementor-section-height-default elementor-section-height-default animated fadeIn" data-id="e8616e9" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeIn&quot;,&quot;animation_delay&quot;:200}">
                        <div className="elementor-container elementor-column-gap-no">
                            <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-0bd5410" data-id="0bd5410" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-63f347b elementor-widget elementor-widget-heading" data-id="63f347b" data-element_type="widget" data-widget_type="heading.default">
                                        <div className="elementor-widget-container">
                                            <h4 className="mega-menu-link">Contact us</h4> 
                                        </div>
                                    </div>
                                    <div className="elementor-element elementor-element-c65b9bb elementor-widget elementor-widget-text-editor" data-id="c65b9bb" data-element_type="widget" data-widget_type="text-editor.default">
                                        <div className="elementor-widget-container">
                                            <strong>Customer and Technical Support</strong><br />
                                            Monday — Friday:<br />
                                            8:30 AM EST - 5:30 PM EST </div>
                                    </div>
                                    <div className="elementor-element elementor-element-a9df2e2 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="a9df2e2" data-element_type="widget" data-widget_type="icon-list.default">
                                        <ul className='elementor-icon-list-items'>
                                            <li className="elementor-icon-list-item">
                                                <a href="tel:+18887237279">
                                                    <span className="elementor-icon-list-text">888.723.7279</span>
                                                </a>
                                            </li>
                                            <li className="elementor-icon-list-item">
                                                <a href="tel:+18138353883">
                                                    <span className="elementor-icon-list-text">813.835.3883</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-caa34b5" data-id="caa34b5" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-55b2a9c elementor-widget elementor-widget-heading" data-id="55b2a9c" data-element_type="widget" data-widget_type="heading.default">
                                        <div className="elementor-widget-container">
                                            <h4 className="mega-menu-link">Quick Links</h4> </div>
                                    </div>
                                    <div className="elementor-element elementor-element-5cbcdb2 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="5cbcdb2" data-element_type="widget" data-widget_type="icon-list.default">
                                        <div className="elementor-widget-container">
                                            <ul className="elementor-icon-list-items">
                                                <li className="elementor-icon-list-item">
                                                    <a href="/submit-rfq/">
                                                        <span className="elementor-icon-list-text">Submit RFQ</span>
                                                    </a>
                                                </li>
                                                <li className="elementor-icon-list-item">
                                                    <a href="/products/">
                                                        <span className="elementor-icon-list-text">Product Catalogue </span>
                                                    </a>
                                                </li>
                                                <li className="elementor-icon-list-item">
                                                    <a href="#elementor-action%3Aaction%3Dpopup%3Aopen%26settings%3DeyJpZCI6Ijg0NzA4OSIsInRvZ2dsZSI6ZmFsc2V9">
                                                        <span className="elementor-icon-list-text">Quality Policy</span>
                                                    </a>
                                                </li>
                                                <li className="elementor-icon-list-item">
                                                    <a href="https://www.directics.com/wp-content/uploads/2023/02/DCI_Linecard_Feb2023_V4_Blue-compressed.pdf" target="_blank" rel="noreferrer">
                                                        <span className="elementor-icon-list-text">Direct Components Line Card</span>
                                                    </a>
                                                </li>
                                                <li className="elementor-icon-list-item">
                                                    <a href="https://www.directics.com/wp-content/uploads/2022/11/Purchase-Order-Terms-Conditions-Rev-002.pdf%22" target="_blank" rel="noreferrer">
                                                        <span className="elementor-icon-list-text">Purchase Order Terms &amp; Conditions</span>
                                                    </a>
                                                </li>
                                                <li className="elementor-icon-list-item">
                                                    <a href="https://www.directics.com/wp-content/uploads/2022/10/Standard-Sales-Terms-and-Conditions-Rev-005.pdf" target="_blank" rel="noreferrer">
                                                        <span className="elementor-icon-list-text">Sales Terms &amp; Conditions</span>
                                                    </a>
                                                </li>
                                                <li className="elementor-icon-list-item">
                                                    <a href="https://www.directics.com/wp-content/uploads/2023/02/AS9120B-ISO-2015-Cert-AS16186-EXP-010425.pdf" target="_blank" rel="noreferrer">
                                                        <span className="elementor-icon-list-text">ISO 9001:2015 and AS9120:2016 Certificate</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-9b43ae4" data-id="9b43ae4" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-fa5651a elementor-widget elementor-widget-heading" data-id="fa5651a" data-element_type="widget" data-widget_type="heading.default">
                                        <div className="elementor-widget-container">
                                            <h4 className="mega-menu-link">About Us</h4> </div>
                                    </div>
                                    <div className="elementor-element elementor-element-826fa10 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="826fa10" data-element_type="widget" data-widget_type="icon-list.default">
                                        <div className="elementor-widget-container">
                                            <ul className="elementor-icon-list-items">
                                                <li className="elementor-icon-list-item">
                                                    <a href="https://www.directics.com/about/">
                                                        <span className="elementor-icon-list-text">About</span>
                                                    </a>
                                                </li>
                                                <li className="elementor-icon-list-item">
                                                    <a href="https://www.directics.com/team/">
                                                        <span className="elementor-icon-list-text">Team</span>
                                                    </a>
                                                </li>
                                                <li className="elementor-icon-list-item">
                                                    <a href="https://www.directics.com/blog/">
                                                        <span className="elementor-icon-list-text">Blog</span>
                                                    </a>
                                                </li>
                                                <li className="elementor-icon-list-item">
                                                    <a href="https://www.directics.com/dci-careers/">
                                                        <span className="elementor-icon-list-text">Careers</span>
                                                    </a>
                                                </li>
                                                <li className="elementor-icon-list-item">
                                                    <a href="https://www.directics.com/contact/">
                                                        <span className="elementor-icon-list-text">Contact Us</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-51d4406" data-id="51d4406" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated elementor-123">
                                    <div className="elementor-element elementor-element-aafd209 elementor-shape-square e-grid-align-right e-grid-align-tablet-left elementor-grid-0 elementor-widget elementor-widget-social-icons" data-id="aafd209" data-element_type="widget" data-widget_type="social-icons.default">
                                        <div className="elementor-widget-container">
                                            <div className="elementor-social-icons-wrapper elementor-grid">
                                                <span className="elementor-grid-item">
                                                    <a className="elementor-icon elementor-social-icon elementor-social-icon-facebook-square elementor-repeater-item-9d24663" href="https://www.facebook.com/pages/Direct-Components-Inc/1533161330240930?fref=nf" target="_blank" rel="noreferrer">
                                                        <span className="elementor-screen-only"><FaFacebookSquare /></span>
                                                        <i className="fab fa-facebook-square" aria-hidden="true"></i> </a>
                                                </span>
                                                <span className="elementor-grid-item">
                                                    <a className="elementor-icon elementor-social-icon elementor-social-icon-twitter-square elementor-repeater-item-ebcaebb" href="https://twitter.com/DirectCompInc" target="_blank" rel="noreferrer">
                                                        <span className="elementor-screen-only"><FaTwitterSquare /></span>
                                                        <i className="fab fa-twitter-square" aria-hidden="true"></i> </a>
                                                </span>
                                                <span className="elementor-grid-item">
                                                    <a className="elementor-icon elementor-social-icon elementor-social-icon-linkedin elementor-repeater-item-2bf5e2b" href="https://www.linkedin.com/company/direct-components-inc/" target="_blank" rel="noreferrer">
                                                        <span className="elementor-screen-only"><FaLinkedin /></span>
                                                        <i className="fab fa-linkedin" aria-hidden="true"></i> </a>
                                                </span>
                                                <span className="elementor-grid-item">
                                                    <a className="elementor-icon elementor-social-icon elementor-social-icon-youtube-square elementor-repeater-item-5db150f" href="https://www.youtube.com/channel/UCv8IEVGTDT_SekvpmO6FHgg" target="_blank" rel="noreferrer">
                                                        <span className="elementor-screen-only"><FaYoutubeSquare /></span>
                                                        <i className="fab fa-youtube-square" aria-hidden="true"></i> </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="elementor-element elementor-element-71b8734 elementor-widget elementor-widget-image" data-id="71b8734" data-element_type="widget" data-widget_type="image.default">
                                        <div className="elementor-widget-container">
                                            <a href="https://www.directics.com/certifications-memberships" target="_blank" rel="noreferrer">
                                            <img src="https://www.directics.com/wp-content/uploads/2023/01/Logo-Cluster@2000x-1.png" className="attachment-full size-full wp-image-847695" alt="" loading="lazy" srcSet="https://www.directics.com/wp-content/uploads/2023/01/Logo-Cluster@2000x-1.png 1821w, https://www.directics.com/wp-content/uploads/2023/01/Logo-Cluster@2000x-1-300x89.png 300w, https://www.directics.com/wp-content/uploads/2023/01/Logo-Cluster@2000x-1-1024x305.png 1024w, https://www.directics.com/wp-content/uploads/2023/01/Logo-Cluster@2000x-1-768x229.png 768w, https://www.directics.com/wp-content/uploads/2023/01/Logo-Cluster@2000x-1-1536x457.png 1536w" sizes="(max-width: 1821px) 100vw, 1821px" width="542" height="542" /> 
                                            </a>
                                        </div>

                                    </div>
                                    <div className="elementor-element elementor-element-9d09944 elementor-widget elementor-widget-text-editor" data-id="9d09944" data-element_type="widget" data-widget_type="text-editor.default">
                                        <div className="elementor-widget-container">
                                            © DCI 2023 </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >
        </footer >
    )
}

export default Footer;