import React, { useState, useEffect } from 'react';
import SingleOrderList from '../../components/OrderList';
import Loading from '../../components/Loading';

function Orders () {
    useEffect(() => {
        document.title = 'Customer Portal - Orders';
    }, []);

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        try {
            setOrders(JSON.parse(sessionStorage.getItem('orders')).filter(order => order.order_status !== "draft" && order.order_status !== "pending_approval"));
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    }, []);

    return (
        <div className='orders-container'>
            {loading 
            ? <Loading />
            : 
            (<>
            <h2 className='list-header'>Sales Orders - Showing {orders ? `${orders.length}/${orders.length}` : '0/0'}</h2>
            <div className='table-wrapper'>
            <table className='table'>
                <thead>
                    <tr className='grid-row'>
                        <th className=''>Sales Order #</th>
                        <th className=''>Purchase Order #</th>
                        <th className=''>Reference #</th>
                        <th className=''>Date</th>
                        <th className=''>Total</th>
                    </tr>
                </thead>

                <tbody>
                    {(orders && orders.length > 0) 
                        && 
                        orders.map(order => (
                            <SingleOrderList key={order.salesorder_id} order={order} />
                        ))}
                </tbody>
            </table>
            </div>

            {(!orders || orders.length === 0) 
                &&
                (
                    <div className='no-data'>
                        There are no orders for your account
                    </div>
                )}
            </>)}
        </div>
    );
}

export default Orders;