import React from 'react';
import { AiOutlineFileAdd } from 'react-icons/ai';
import IndividualFile from './IndividualFile';

function DocumentUpload({ documentsUploaded, setDocumentsUploaded }) {

    const setOrAddFile = (file, index) => {
        let documentArray = [...documentsUploaded];
        documentArray[index] = file;
        setDocumentsUploaded(documentArray);
    }

	return (
		<div id="docu-container">
			<div id="docu-uploaded-wrapper">
				<form id='document-form' style={{ display: 'flex', flexDirection: 'column' }}>
				{documentsUploaded.map((_, index) => (
					<IndividualFile key={'file'+ index} index={index} setOrAddFile={setOrAddFile} />
				))}
				</form>
			</div>
			{documentsUploaded.length < 5 && (
				<div
					id="docu-add-another"
					style={{ fontSize: 'large', marginTop: '10px' }}
					onClick={(e) =>
						setDocumentsUploaded([...documentsUploaded, ''])
					}
				>
					<AiOutlineFileAdd /> Add New
				</div>
			)}
		</div>
	);
}

export default DocumentUpload;
