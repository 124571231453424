import React from 'react';

function ExtraInformation() {
	return (
		<>
			<tr>
				<td colSpan={2}>Reason for return</td>
				<td className="align-right">
					<textarea
						type="text"
						id="Reason_for_Return"
						className="part-amt-box part-reason-box"
						wrap="hard"
					/>
				</td>
			</tr>
			<tr>
				<td colSpan={2}>
					<span>Have you used this part before?</span>
				</td>
				<td className="align-right">
					<select id="Have_you_used_this_part_before" defaultValue={'default'}>
						<option name="yes">Yes</option>
						<option name="no">No</option>
						<option value="default" hidden={true}>
							-Select-
						</option>
					</select>
				</td>
			</tr>
			<tr>
				<td colSpan={2}>
					<span>
						Have there been problems with other batches in the past?
					</span>
				</td>
				<td className="align-right">
					<select id="Problems_with_other_prior_batches" defaultValue={'default'} className={``}>
						<option name="yes" value="Yes">
							Yes
						</option>
						<option name="no" value="No">
							No
						</option>
						<option
							hidden={true}
							value="default"
						>
							-Select-
						</option>
					</select>
				</td>
			</tr>
			<tr>
				<td colSpan={2}>
					<span>Have you tried a part from a different batch?</span>
				</td>
				<td className="align-right">
					<select id="Have_you_tried_a_part_from_a_different_batch" defaultValue={'default'}>
						<option name="yes">Yes</option>
						<option name="no">No</option>
						<option value={'default'} hidden={true}>
							-Select-
						</option>
					</select>
				</td>
			</tr>
			<tr>
				<td colSpan={2}>
					<span>How many have been tested?</span>
				</td>
				<td className="align-right">
					<input
						type="number"
						id="How_many_have_been_tested"
						className="part-amt-box"
					/>
				</td>
			</tr>
			<tr>
				<td colSpan={2}>
					<span>How many have failed?</span>
				</td>
				<td className="align-right">
					<input
						type="number"
						id="How_many_failed"
						className="part-amt-box"
					/>
				</td>
			</tr>
			<tr>
				<td colSpan={2}>
					<span>How many were mounted on boards?</span>
				</td>
				<td className="align-right">
					<input
						type="number"
						id="How_many_were_mounted_on_boards"
						className="part-amt-box"
					/>
				</td>
			</tr>
			<tr>
				<td colSpan={2}>
					<span>Country the Components will ship from: </span>
				</td>
				<td className="align-right">
					<textarea
						type="text"
						id="Country_Parts_Will_Ship_From"
						className="part-amt-box part-country-box"
					/>
				</td>
			</tr>
			<tr>
				<td colSpan={2}>
					<span>Preferred resolution?</span>
				</td>
				<td className="align-right">
					<select id="Preferred_Resolution" defaultValue={'default'}>
						<option name="replacement">Replacement</option>
						<option name="credit">Credit</option>
						<option name="outsourced">Outsourced Repair</option>
						<option value={'default'} hidden={true}>
							-Select-
						</option>
					</select>
				</td>
			</tr>
		</>
	);
}

export default ExtraInformation;
