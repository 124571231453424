import React, { useEffect, useState } from 'react';
import Loading from "../../components/Loading";
import { gatherUserData, gatherInvoiceData, gatherOrderData, gatherAccountData } from '../../utils/Data_Grabber';
import Auth from '../../utils/auth';

function AuthRedirect () {
    document.title = 'Redirecting...';
    const [currentLoad, setCurrentLoad] = useState(0);

    if (sessionStorage.getItem('invoices') !== null &&
        sessionStorage.getItem('orders') !== null &&
        sessionStorage.getItem('user') !== null &&
        sessionStorage.getItem('account') !== null) {
            window.location.assign('/home');
        }

    async function getInvoices () {
        if (sessionStorage.getItem('invoices') === null) {
            await gatherInvoiceData()
            .then(setCurrentLoad(currentLoad + 1));
        }
    }

    async function getOrders () {
        if (sessionStorage.getItem('orders') === null) {
            await gatherOrderData()
            .then(sessionStorage.setItem('edl',false))
            .then(setCurrentLoad(currentLoad + 1));
        }
    }

    async function getUserData () {
        if (sessionStorage.getItem('user') === null) {
        await gatherUserData()
        .then(setCurrentLoad(currentLoad + 1));
        }
    }

    async function getAccountData () {
        if (sessionStorage.getItem('account') === null) {
            await gatherAccountData()
            .then(setCurrentLoad(currentLoad + 1))
            .then(setCountdown(2));
        }
    }

    useEffect(() => {
        getUserData();
        getInvoices();
        getAccountData();
        getOrders();
        fetch("/auth/login/time/" + Auth.getProfile().zoho_id[JSON.parse(localStorage.getItem("accindex"))], {
            method: 'post',
            headers: {
                "Content-Type": "application/json"
            }
        })
    },[]);

    // create countdown to redirect user,
    // assuring information is added to session storage before continuing
    const [countdown, setCountdown] = useState(-1);
    useEffect(() => {
        const timer = setTimeout(() => {
            setCountdown(countdown - 1)
        }, 1000);
        if (countdown === 0) {
            clearTimeout(timer);
            //window.location.replace('/home');
        }
    },[countdown]);

    return (
        <div style={{ paddingTop: "25px"}}>

            <div className="title">
                <h2 style={{ fontWeight: '400' }}>Successful</h2>
            </div>
            <div className="" style={{ textAlign: 'center' }}>
                <p>Thank you for logging in</p>
                <p>Please wait while we fetch your information...</p>
            </div>
            <div style={{ margin: "auto"}}>
                <Loading />
            </div>
            <div className={`modal-background`} style={{ backgroundColor: 'transparent' }} />
        </div>
    );
};

export default AuthRedirect;
