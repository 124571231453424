import React, { useState, useEffect, useRef } from "react";
import './index.css';
import { formatDate } from "../../../utils/helpers";

let orderStep;
const words = [
    {"track-current": ["Order in","Process"], "track-complete": ['Order', 'Submitted']},
    {"track-current": ["Quality","Check"], "track-complete": ["Quality","Check"]},
    {"track-current": ["Customer","Acknowledgement"], "track-complete": ["Order","Acknowledged"]},
    {"track-current": ["Processing",""], "track-complete": ["Processed",""]},
    {"track-current": ["Testing",""], "track-complete": ["Testing","Complete"]},
    {"track-current": ["Shipment","Pending"], "track-complete": ["Shipping","Complete"], "track-error": ["Error with","Shipment"]}
];

function getStep (soStatus, pc, error) {
    switch(soStatus) {
        case "draft":
            return 0;
        case "pending_approval":
            return 1;
        case "approved":
            return 2;
        case "confirmed":
            case "open":
            if (!pc) return 3;
            else if (error) return 5;
            else return 4;
        case "invoiced":
        case "partially_invoiced":
            return 5;
        default:
            return 0;
    }
}

function getStepStatus (step,information) {
    if (step < orderStep)
        return "complete";
    if (step === orderStep && step !== 5)
        return "current";
    if (step === 5) {
        if (information.deal.error)
            return "error";
        else if (information.status === "invoiced" || information.status === "partially_invoiced")
            return "complete";
        else
            return "";
    }
    else return "";
}

function getStepWords (index, step, word, information) {
    let status = getStepStatus(step,information);
    if (status === "complete")
        return word["track-complete"][index];
    else if (status === "current" || status === "")
        return word["track-current"][index];
    else if (status === "error")
        return word["track-error"][index];
}

export function PizzaTracking ({information}) {
    const [expanded, setExpanded] = useState(false);
    const [height, setHeight] = useState(0);
    useEffect(() => {
        if (!information.deal)
            return (<></>);
        setHeight(ref.current.scrollHeight);
    },[information.deal])
    orderStep = getStep(information.status === "draft" ? information.order_status : information.status,information.deal.pc,information.deal.error);
    const ref = useRef(null);
    
    return (
            <div className={`card ${expanded ? "highlight" : ""}`} id={`tracking-${information.salesorder_number}`}>
                <div className={`${information.packages.length > 0 ? "packages" : ""} track-title track-border`} onClick={function (e) { setExpanded(!expanded)}}>
                    <div className="row d-flex justify-content-between top">
                        <div>
                            {information.order_status === "draft" || information.order_status === "pending_approval"
                            ? (
                                <span className="card-id" href="">{information.salesorder_number}</span>
                            ) : (
                                <a className="card-id card-click" href={`/orders/${information.salesorder_id}`}>{information.salesorder_number}</a>
                            )
                            }
                        </div>
                        <div className="d-flex f-column text-sm-right flex-grow lh-none">
                            {information.packages.length === 1 
                            ? (
                                <>
                                    <span className="font-weight-bold">{information.packages[0].tracking_number}</span>
                                </>
                           ) : 
                           information.packages.length > 1 &&
                           (
                                <span>Multiple shipments</span>
                           )}
                        </div>
                    </div>
                    {/* <!-- Add className 'track-complete' to progress --> */}
                    <div className="row d-flex justify-content-center f-column">
                        <ul id="progressbar" className="text-center">
                            {words.map((word,index) => (
                                <li className={`track-${getStepStatus(index,information)} step0`} key={`${word[0]}-${index}`}>
                                    <br/>{getStepWords(0,index,word,information)}
                                    <br/>{getStepWords(1,index,word,information)}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className={`d-flex track-content track-border d-flex`} ref={ref} style={{height: (expanded ? height : 0)}}>
                    <div className={`d-flex flex-grow justify-content-between flex-wrap ${information.packages.length === 1 ? 'f-column' : ''}`}>
                    {information.packages.map((p, index) => (
                        <table className="package-table" key={p.package_number}>
                            <thead>
                                <tr>
                                    <td colSpan="2"><span>Shipment {index + 1} of {information.packages.length}</span></td>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>
                                    <td>Package #:</td><td>{p.package_number}</td>
                                </tr> */}
                                <tr>
                                    <td className="tracking-label">Tracking #:</td>
                                    <td className="tracking">
                                        <span>{p.tracking_number}</span><span className="delivery">{p.delivery_method}</span>
                                    </td>
                                    
                                </tr>
                                <tr>
                                    <td>Shipment Date:</td><td>{formatDate(p.shipment_date)}</td>
                                </tr>
                            </tbody>
                        </table>
                    ))}
                    </div>
                    {/* <div id="side-help" className="d-flex f-column">
                        <a href="mailto:orders@directics.com">Help?</a>
                        <a href="https://www.directics.com/survey/" target="_blank" rel="noreferrer">Survey</a>
                    </div> */}
                </div>
            </div>
    )
}