import React from 'react';

function Loading () {
    return (
        <div className='loading'>
            <div className='load' />
        </div>
    )
}

export default Loading;