import React from 'react';
import { useNavigate } from "react-router-dom";
import { spaceAndCapitalize, formatPrice, formatDate } from '../../utils/helpers';
import { AiOutlineFilePdf } from "react-icons/ai";
import axios from 'axios';
import { toast } from "react-toastify";

const downloadPDF = (invoice_id, invoice_number) => {
    toast.success("Downloading " + invoice_number, {
        autoClose: 1400,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
    });
    axios.get(`/api/invoices/${invoice_id}/pdf`,
        {
        responseType: 'blob',
        })
        .then((data) => {
            const url = URL.createObjectURL(data.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = `DCI ${invoice_number}.pdf`;
            link.dispatchEvent(new MouseEvent('click'));
        })
        .catch((err) => {
            toast.error("Unable to download file");
        });
}

function InvoiceList ({ invoice }) {
    const handlePaidStatus = (status) => {
        if (status === 'paid') {
            return 'green';
        } else if (status === 'overdue') {
            return 'red';
        } else if (status === 'partially_paid') {
            return 'orange';
        }
    }

    let navigate = useNavigate(); 
    const routeChange = () => {  
        navigate(`/invoices/${invoice.invoice_id}`);
    }

    return (
        <tr className='order' key={invoice.invoice_id} id={invoice.invoice_id}>
            <th onClick={routeChange}>{invoice.invoice_number}</th>
            <th onClick={routeChange}>{invoice.reference_number.match(/(SO|INV)?\-?[0-9]*/gi)}</th>
            <th onClick={routeChange}>{formatDate(invoice.date)}</th>
            <th onClick={routeChange}>{formatPrice(invoice.total, 'en-US', invoice.currency_code)}</th>
            <th 
                style={{color: handlePaidStatus(invoice.status)}}
                onClick={routeChange}
            >
                {spaceAndCapitalize(invoice.status)}
            </th>
            <th className='pdf' onClick={()=>downloadPDF(invoice.invoice_id, invoice.invoice_number)}><AiOutlineFilePdf /></th>
        </tr>
    );
}

export default InvoiceList;