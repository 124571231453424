import React, { useState } from "react";
import IndividualPart from "../PartList/IndividualPart";

function RmaFormSection({ data }) {
  const [formState, setFormState] = useState([{}]);

  const handleValueChange = (e) => {
    setFormState([...formState, { id: e.target.id, value: e.target.value }]);
  };

  return (
    <>
      {data.map((formItem, index) => (
        <div key={`${formItem.value}`} className='topForm' style={{ width: '100%' }}>
          {Array.isArray(formItem.value) ? (
            <>
              {formItem.type === "select-multiple" ? (
                <>
                {formItem.value.map((part, index) => (
                  <div key={part.name + 'i'+ index}>
                    <IndividualPart part={part} />
                  </div>
                ))}
                </>
              ) : (
                <div className="form-items-container" key={formItem.value} id={formItem.value}>
                  <label>
                    {formItem.display}
                    <span style={{ color: "red" }}>
                      {formItem.required && "*"}
                    </span>
                  </label>
                  {formItem.value.length === 0 &&
                  formItem.value[0] === undefined ? (
                    <input
                      name={formItem.display}
                      id={formItem.display}
                      onChange={handleValueChange}
                      style={{ textAlign: 'end' }}
                    />
                  ) : (
                    <select
                      name={formItem.display}
                    >
                      {formItem.value.map((item, index) => (
                        <option key={item + 'i:' + index} value={item}>{item}</option>
                      ))}
                      {/* <option>Add another...</option> */}
                    </select>
                  )}
                </div>
              )}
            </>
          ) : (
            <div className="form-items-container" key={formItem.value} id={formItem.value}>
              <label>
                {formItem.display}
                <span style={{ color: "red" }}>{formItem.required && "*"}</span>
              </label>
              <p style={{ lineHeight: 0, fontWeight: 500 }}>{formItem.value}</p>
            </div>
          )}
        </div>
      ))}
    </>
  );
}

export default RmaFormSection;
