import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineDownload } from "react-icons/ai";
import RmaModal from "../RmaModal";
import axios from "axios";


function SingleNavBar({ text, data, pdf, setPdf }) {
  const whatId = useParams();

  const [rmaModalOpen, setRmaModal] = useState({ show: false });

  const openRmaModal = () => {
    setRmaModal({ show: true });
  };

  const closeModal = () => {
    setRmaModal({ show: false });
  };

  const navigate = useNavigate(); 
  const routeChange = () => {  
      navigate(-1);
  }

  useEffect(() => {
    getPdf(); 
  }, [])

  async function getPdf () {
    axios.get(`/api/${Object.keys(whatId).includes("invoiceid") ? `invoices/${whatId.invoiceid}` : `orders/${whatId.orderid}`}/pdf`,
      {
        responseType: 'blob',
      })
      .then((data) => {
        const url = URL.createObjectURL(data.data);
        setPdf(url);
      })
      .catch((err) => {
        
      });
  }
  
  async function downloadFile (e) {
		e.preventDefault();

    try {
      const link = document.createElement('a');
      link.href = pdf;
      link.download = `${Object.keys(whatId).includes("invoiceid") ? `DCI ${data.invoice_number}.pdf` : `DCI ${data.salesorder_number}.pdf`}`;
      link.dispatchEvent(new MouseEvent('click'));
    } catch (e) {
      return;
    }
  }

  return (
    <div className="invoice-nav">
      <div onClick={routeChange} className="back-link link flex">
        <AiOutlineLeft />
        <div>{text}</div>
      </div>
      <div className="flex btn-group">
        {/* RMA return - only include for invoices */}
        {Object.keys(whatId).includes("invoiceid") && (
          <>
            <div
              className={`btn ${data ? 'rma-container box-border' : 'disabled'}`}
              onClick={data && openRmaModal}
            >
              Request RMA
            </div>
            <RmaModal
              show={rmaModalOpen.show}
              closeModal={closeModal}
              data={data}
            />
          </>
        )}

        <div className="box-border btn" id="download-pdf" onClick={downloadFile}>
          <AiOutlineDownload />
        </div>
        {/* <div className="box-border btn" id="print-pdf" onClick={downloadOrPrint}>
          <BsPrinter />
        </div> */}
      </div>
    </div>
  );
}

export default SingleNavBar;
