import React, {useState } from 'react';
import './index.css';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { RiArrowDownSLine } from 'react-icons/ri'

function TopNav () {
    const [navbarOpen,setNavbarOpen] = useState(false);
    return (
    <div className={`nav-container ${navbarOpen ? 'navbar-open' : ''}`} style={{ zIndex: 19 }}>
        <div className="logo-section">
            <a href="https://www.directics.com">
                <img src="https://www.directics.com/wp-content/uploads/2022/07/DICO-HORIZ-LOGO_GRADIENT-1.png" alt="logo" width="225" />
            </a>
        </div>
        <div className="nav">
            <div className="container">
                <div className="navbar-section">
                    <div id="mega-menu-wrap-primary-menu" className="mega-menu-wrap">
                        <div className="mega-menu-toggle">
                            <div className="mega-toggle-blocks-left"></div>
                            <div className="mega-toggle-blocks-center"></div>
                            <div className="mega-toggle-blocks-right">
                                <div className="mega-toggle-block mega-menu-toggle-block mega-toggle-block-1" id="mega-toggle-block-1" tabIndex="0">
                                    <span className="mega-toggle-label" role="button" aria-expanded="false">
                                        <span className="mega-toggle-label-closed">
                                            {!navbarOpen 
                                            ?
                                              <AiOutlineMenu className='icon' style={{ verticalAlign: 'text-bottom', paddingLeft: '5px', fontSize: 'x-large', color: "#1c449b"}} 
                                                    onClick={(e) => setNavbarOpen(!navbarOpen)}/>
                                            : <AiOutlineClose className='closed-icon' style={{ verticalAlign: 'text-bottom', paddingLeft: '5px', fontSize: 'x-large', color: "#1c449b"}} 
                                                    onClick={(e) => setNavbarOpen(!navbarOpen)}
                                                />
                                            }
                                        </span>
                                        <span className="mega-toggle-label-open"
                                            onClick={(e) => e.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.children[1].classList.toggle('mega-menu-open')}>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <ul id="mega-menu-primary-menu" className={`mega-menu max-mega-menu mega-menu-horizontal ${navbarOpen ? 'mega-menu-open' : ''}`} data-event="hover" data-effect="fade_up" data-effect-speed="200" data-effect-mobile="disabled" data-effect-speed-mobile="0" data-mobile-force-width="false" data-second-click="go" data-document-click="collapse" data-vertical-behaviour="standard" data-breakpoint="768" data-unbind="true" data-mobile-state="collapse_all" data-hover-intent-timeout="300" data-hover-intent-interval="100">
                            <li className="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-home mega-current-menu-item mega-page_item mega-page-item-150 mega-current_page_item mega-align-bottom-left mega-menu-flyout mega-menu-item-177" id="mega-menu-item-177">
                                <a className="mega-menu-link" href="https://www.directics.com/about" tabIndex="0">
                                    About
                                </a>
                            </li>

                            {/* Company */}
                            <li className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-megamenu mega-align-bottom-left mega-menu-megamenu mega-has-icon mega-icon-left mega-menu-item-136490" 
                                id="mega-menu-item-136490"
                                onMouseEnter={(e) => e.currentTarget.classList.toggle('mega-toggle-on')}
                                onMouseLeave={(e) => e.currentTarget.classList.toggle('mega-toggle-on')}>
                                <a className="mega-menu-link" href="https://www.directics.com/team/" tabIndex="0">
                                    Team
                                    <RiArrowDownSLine style={{ verticalAlign: 'text-bottom', paddingLeft: '3px' }} />
                                </a>
                                <ul className="mega-sub-menu">
                                <li className="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-has-icon mega-icon-left mega-menu-columns-2-of-8 mega-menu-item-136501" id="mega-menu-item-136501">
                                        <a className="mega-sub-link mega-menu-link" href="https://www.directics.com/dci-careers/">
                                            Careers
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            
                            {/* Services */}
                            <li className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-megamenu mega-align-bottom-left mega-menu-megamenu mega-menu-item-136491" 
                                id="mega-menu-item-136491"
                                onMouseEnter={(e) => e.currentTarget.classList.toggle('mega-toggle-on')}
                                onMouseLeave={(e) => e.currentTarget.classList.toggle('mega-toggle-on')}>
                                <a className="mega-menu-link" href="https://www.directics.com/products/" tabIndex="0">
                                    Services
                                    <RiArrowDownSLine style={{ verticalAlign: 'text-bottom', paddingLeft: '3px' }} />
                                </a>
                                <ul className="mega-sub-menu">
                                    <li className="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-has-icon mega-icon-left mega-menu-columns-3-of-8 mega-menu-item-136500" id="mega-menu-item-136500">
                                        <a className="mega-sub-link mega-menu-link" href="https://www.directics.com/surplus-inventory-management/">
                                            Surplus Inventory Management
                                        </a>
                                    </li>
                                    <li className="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-has-icon mega-icon-left mega-menu-columns-2-of-8 mega-menu-item-136501" id="mega-menu-item-136501">
                                        <a className="mega-sub-link mega-menu-link" href="https://www.directics.com/value-added-services/">
                                            Value Added Services
                                        </a>
                                    </li>
                                </ul>
                            </li>

                            {/* Products and Parts */}
                            <li className="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-align-bottom-left mega-menu-flyout mega-menu-item-844708" id="mega-menu-item-844708">
                                <a className="mega-menu-link" href="https://www.directics.com/products" tabIndex="0">
                                    Products &amp; Parts
                                </a>
                            </li>

                            {/* Downloads */}
                            <li className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-megamenu mega-align-bottom-left mega-menu-megamenu mega-menu-item-136492" 
                                id="mega-menu-item-136492">
                                <a className="mega-menu-link" href="https://www.directics.com/customers/" tabIndex="0">
                                    Downloads
                                </a>
                            </li>

                            {/* Blog */}
                            <li className="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-align-bottom-left mega-menu-flyout mega-menu-item-842955" id="mega-menu-item-842955">
                                <a className="mega-menu-link" href="https://www.directics.com/blog/" tabIndex="0">
                                    Blog
                                </a>
                            </li>

                            {/* Contact */}
                            <li className="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-align-bottom-left mega-menu-flyout mega-menu-item-22" id="mega-menu-item-22">
                                <a className="mega-menu-link" href="https://www.directics.com/contact/" tabIndex="0">
                                    Contact
                                </a>
                            </li>
                            <li className="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-align-bottom-left mega-menu-flyout mega-menu-item-22" id="mega-menu-item-23">
                                <a className="mega-menu-button" href="https://www.directics.com/submit-rfq" tabIndex="0">
                                    Request Quote
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default TopNav;