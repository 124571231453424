import React from 'react';
import "../index.css";

function SignOnHeader () {
    return (<>
        <div>
            <img id='hero-img' src={process.env.PUBLIC_URL+ '/login_hero.png'} alt='Blue background with lines' />
            <img id="hero-text" 
                style={{ 
                    scale: (document.getElementById('root').clientWidth > 1000 ? 2 : (document.getElementById('root').clientWidth/ 1000) + 1)
                }} 
                src={process.env.PUBLIC_URL+ '/login_wordage.png'}
                alt="The Direct Components Logo of a DC and I in the shape of an infinity symbol"
            />
        </div>
        {/* Need Help Question Mark */}
		<a id="login-help-button" href="https://directics.com/customer-portal-guide/" target="_blank" rel="noreferrer">?</a>
    </>)
}

export default SignOnHeader;