import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';
import { gatherPaymentData } from '../../utils/Data_Grabber';
import { formatPrice, formatDate, invoiceNumToId } from '../../utils/helpers';

function Payments () {
    let navigate = useNavigate();

    useEffect(() => {
        document.title = 'Customer Portal - Payments';
    }, []);
    useEffect(() => {
        getPayments().then(() => setLoading(false));
    }, [])

    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);

    async function getPayments () {
        if (sessionStorage.getItem('payments') === null) {
            setPayments(await gatherPaymentData());
        } else {
            setPayments(JSON.parse(sessionStorage.getItem('payments')));
        }
    }

    function getReferenceLink (payment) {
        // there are invoices in the invoice list
        if (payment.invoice_numbers.match(/INV\-\b[0-9]*/gi)) {
            const filtered = payment.invoice_numbers.split(',').filter(invoice => invoice.includes('INV'));
            return filtered;
        } 
        // no invoices in the invoice list
        // parse the description to get the SO number
        else {
            const filtered = payment.description.match(/(SO|INV)\-\b[0-9]*/gi);
            return filtered ? filtered : '-';
        }
    }

    return (<>
        {loading && <Loading />}
        <div className={`invoices-container ${loading && 'hidden'}`}>
            <h2 className='list-header'>Payments</h2>

            <table className='table'>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Payment #</th>
                        <th>References</th>
                        <th>Amount</th>
                        <th>Payment Mode</th>
                    </tr>
                </thead>
                <tbody>
                    {payments && payments.length > 0
                    && 
                    (payments.map(payment => (
                        <tr key={payment.payment_id}>
                            <th>{formatDate(payment.date)}</th>
                            <th style={{ paddingLeft: '5px' }}>{payment.payment_number}</th>
                            <th style={{ maxWidth: '250px', paddingRight: '5px' }}>
                            {getReferenceLink(payment).length > 1 
                            ? 
                            (<>
                                {getReferenceLink(payment).map(i => (
                                    <span className='link' id={i} key={i} onClick={e => navigate('/invoices/' + invoiceNumToId()[(i.replace(',', ""))])}>{i} </span>
                                ))}
                            </>)
                            : 
                            <span className={`${getReferenceLink(payment) !== '-' && 'link'}`}
                                onClick={(e) => 
                                    getReferenceLink(payment) !== '-' && navigate('/invoices/' + invoiceNumToId()[getReferenceLink(payment)])
                                }
                                >
                                {getReferenceLink(payment)}
                            </span>
                            }
                            </th>
                            <th>{formatPrice(payment.amount)}</th>
                            <th>{payment.payment_mode_formatted}</th>
                        </tr>
                    )))}
                    </tbody>
                </table>

                {(!payments || payments.length === 0) 
                &&
                (
                    <div className='no-data'>
                        No payments have been made
                    </div>
                )}
        </div>
    </>)
}

export default Payments;