export const validateForm = (e) => {
	const errors = [];
	const base = e.target.offsetParent.children[2].children;
	const contact = base[0];
	const order = base[1];
	const documents = base[2].children[2].childNodes[0].children[0];

	// Contact information
	const contactInfo = {
		RMA_Requesting_Account: contact.childNodes[1].children[0].id,
		Contact_Name: contact[0].value,
		Contact_Phone: contact[1].value,
		Email: contact[2].value,
	};

	// RMA Order Details
	const partReturnInfo = {
		PO_Number: order.children[1].children[0].id,
		Invoice_Number: order.children[2].children[0].id,
		partsReturned: getPartData(),
	};

	// Document Upload(s)
	if (documents.length > 0) {
		let validFile = false;
		for (const document of documents) {
			document.value !== '' && (validFile = true);
		}
		
		if (!validFile) errors.push('Must upload at least 1 document');
	} else {
		errors.push('Must upload at least 1 document');
	}

	Object.values(contactInfo).forEach((item, index) => {
		if (item === '') {
			errors.push(
				`Missing response for ${Object.keys(contactInfo)[index]}`
			);
		}
	});

	// parse through part data from form to gather all info
	function getPartData() {
		const returnedArray = [];
		for (var i = 0; i < order.length; i += 12) {
			// only loop through parts that have a value in 'Amount' box
			if (parseInt(order[i].value) !== 0) {
				const currentPartData = [];
				for (var j = 0; j < 11; j++) {
					currentPartData.push(order[i + j]);
				}

				// loop through values for current part and add them to obj
				let itemsToAdd = {};
				Object.values(currentPartData).forEach((part) => {
					const { id, value, type } = part 
					if (value === '' || value === 'default') {
						errors.push(
							`${
								order.children[3].children[0].id
							} - Missing response for '${id.replaceAll('_', ' ').toLowerCase()}'`
						);
					} else {
						if (type === 'number' && id !== 'RMA_Quantity') itemsToAdd[id] = parseInt(value);
						else itemsToAdd[id] = value;
					}
				});
				// add part name to order
				itemsToAdd.Name =
					order[i]
						.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.id;
				
				// push current part obj to array that will be returned
				returnedArray.push(itemsToAdd);
			}
		}

		if (returnedArray.length === 0)
			errors.push('Must select at least one component to return');
		return returnedArray;
	}

	return { errors, contactInfo, partReturnInfo };
};
