import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import SingleNavBar from '../../components/SingleNavBar';
import { formatDate, formatPrice } from '../../utils/helpers';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import Loading from '../../components/Loading';
import NotFound from '../NotFound';

function SingleOrder () {
    useEffect(() => {
        const o = JSON.parse(sessionStorage.getItem('orders')).filter(order => order.salesorder_id === orderid)[0]
        setOrder(o);
        if (o.packages === undefined || o.invoices === undefined)
            getOrderDetails();
    }, []);

    const orderid = useParams().orderid;
    let orderNum;
    try {
        orderNum = JSON.parse(sessionStorage.getItem('orders')).filter(order => order.salesorder_id === orderid)[0].salesorder_number;
    } catch (TypeError) {
        orderNum = ''
    }

    useEffect(() => {
        document.title = 'Customer Portal - ' + orderNum;
    }, []);

    const [isActiveTab, setActiveTab] = useState('invoices');
    const handleTabSwitch = (event) => {
        const tab = event.target.textContent.toLowerCase();
        setActiveTab(tab);
    }

    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState({});
    const [pdf, setPdf] = useState();
    const [printPdf, setPrintPdf] = useState();
    const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const onDocumentLoadSuccess = ({ numPages }) => {
		const pages = Array(numPages);
		for (var i = 0; i < numPages; i++) {
			pages[i] = "";
		}
		setNumPages(pages);
		setLoading(false);
	};

    if (!orderNum) {
        return (<NotFound />)
      }

    async function getOrderDetails () {
        const response = await fetch(`/api/orders/${orderid}`, {
            method: 'GET'
        });
        const { data } = await response.json();
        if (response.ok) {
            setOrder(data.salesorder);
            // save packages and invoices to session storage for faster parsing on page
            const packages = data.salesorder.packages;
            const invoices = data.salesorder.invoices;
            const orders = JSON.parse(sessionStorage.getItem('orders')).map(order => {
                if (order.salesorder_id === orderid)
                {
                    order.packages = packages;
                    order.invoices = invoices;
                }
                return order;
            });
            sessionStorage.setItem('orders', JSON.stringify(orders));
        } else {
            // console.error(data.message);
        }
    }

    // async function getOrderPdf () {
    //     axios.get('/api/orders/print/' + orderid, {
    //     responseType: 'blob',
    //   })
    //   .then((data) => {
    //     const url = URL.createObjectURL(data.data);
    //     setPrintPdf(data.data);
    //   })
    //   .catch((err) => {
    //     // if (err.response.statusText === 'Unauthorized') {
    //     //   Auth.reAuth().then(() => this.getPdf());
    //     // }
    //     console.log('error: ' + err);
    //   });
    // }
    
    return (
       <>{loading && 
            <Loading />}
        <div className={`single-order-container ${loading && 'hidden'}`} style={{ width: '100%', maxWidth: '1000px' }}>
            <SingleNavBar text='Back' data={order} pdf={pdf} setPdf={setPdf} />

            <div className='single-order-top-container'>
                <div className='flex'>
                    <div className={`tab ${isActiveTab === 'invoices' ? 'active-tab' : ''}`} 
                        onClick={handleTabSwitch}>
                            Invoices
                    </div>
                    <div className={`tab ${isActiveTab === 'shipment' ? 'active-tab' : ''}`} 
                        onClick={handleTabSwitch}>
                            Shipment
                    </div>
                </div>
                
                <div className='tab-content'>
                    <div className={`tab-pane fade ${isActiveTab === 'invoices' ? 'active-tab-content show' : 'display-none'}`}>
                        <div className='table-wrapper'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Invoice #</th>
                                        <th>Total</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {order.invoices ? (<>
                                        {order.invoices.length > 0 ? (<>
                                            {order.invoices.map((invoice) => (
                                            <tr key={invoice.invoice_id}>
                                                <th>
                                                    <Link to={`/invoices/${invoice.invoice_id}`}>
                                                    {invoice.invoice_number}
                                                    </Link>
                                                </th>
                                                <th>{formatPrice(invoice.total, 'en-US', 'USD')}</th>
                                                <th>{formatDate(invoice.date)}</th>
                                            </tr>
                                            ))}
                                        </>) : (
                                            <tr><th>No invoices</th></tr>
                                        )}
                                    </>) : (
                                        <tr><th><Loading /></th></tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={`tab-pane fade ${isActiveTab === 'shipment' ? 'active-tab-content show' : 'display-none'}`}>
                        <div className='table-wrapper'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Package #</th>
                                        <th>Carrier</th>
                                        <th>Shipment Date</th>
                                        <th>Shipment Status</th>
                                        <th>Tracking Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {order.packages && (<>
                                        {order.packages.length > 0 ? (<>
                                            {order.packages.map((pack) => (
                                            <tr key={pack.package_number}>
                                                <th>{pack.package_number}</th>
                                                <th>{pack.delivery_method || '-'}</th>
                                                <th>{pack.shipment_date === '' ? 'No delivery date' : formatDate(pack.shipment_date)}</th>
                                                <th>{pack.shipment_status || '-'}</th>
                                                <th>{pack.tracking_number || '-'}</th>
                                            </tr>
                                            ))}
                                        </>) : (
                                            <tr><th>No shipments</th></tr>
                                        )}
                                    </>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`border pdf-view-container`}>
            <Document
                file={pdf}
                onLoadSuccess={onDocumentLoadSuccess}
                >
                    {numPages && numPages.map((_, index) => (
                    <Page 
                        key={'page'+ index + 1}
                        scale={document.getElementById('root').clientWidth / 1250 * (96/72)}
                        pageNumber={index + 1}>
							<p className='pdf-page-num'></p>
                    </Page>
                    ))}
            </Document>
            </div>
        </div>
    </>);
}

export default SingleOrder;