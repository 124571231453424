import React, { useState } from 'react';
import IndividualItemRMARow from './IndividualItemRMARow';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';

function IndividualPart({ part }) {
	const [rows, setRows] = useState(['']);
	const [showDescription, setDesription] = useState(false);
	const [selected, setSelected] = useState(false);

	const addRows = () => {
		setRows([...rows, '']);
	};

	return (
		<div
			className={`part-container ${selected && 'part-selected'}`}
			id={part.name}
		>
			<div className="part-items">
				<div className="part-info">
					{/* Part Name */}
					<div>
						<div className="part-name">{part.name}</div>
					</div>
					
					{/* Part Description */}
					<div
						className={`sub-heading-wrapper ${
							showDescription ? '' : 'hide-heading'
						}`}
						onClick={(e) => setDesription(!showDescription)}
					>
						{showDescription ? <MdExpandLess /> : <MdExpandMore />}
						<span className={`sub-heading`}>
							{part.description}
						</span>
					</div>
				</div>

				<div className="part-amt-wrapper">
					<div className="">
						<div className="flex">
							<span style={{ paddingBottom: '5px' }}>
								Ordered{' '}
								<span style={{ fontWeight: '600' }}>
									{part.quantity}
								</span>{' '}
								{part.unit}
							</span>
						</div>
					</div>

					<table className="rma-parts-table">
						<thead>
							<tr className="part-row-header">
								<th style={{ width: '23.5%' }}>Amount</th>
								<th>Date Code</th>
								<th></th>
							</tr>
						</thead>
					</table>

						{rows.map((_, index) => (
							<IndividualItemRMARow
								key={part.name + '' + part.quantity+'row'+index}
								part={part}
								addRows={addRows}
								last={index + 1 === rows.length && true}
								setSelected={setSelected}
							/>
						))}
				</div>
			</div>
		</div>
	);
}

export default IndividualPart;
