import React, { useState, useEffect } from 'react';
import { getData } from '../../utils/invoice_modal_data';
import DocumentUpload from './DocumentUpload';
import { postFormData } from './postFormData';
import RmaFormSection from './RmaFormSection';
import { validateForm } from './validateForms';
import { toast } from 'react-toastify';

function RmaModal(props) {
	const [submitErrors, setSubmitErrors] = useState([]);
	const [documentsUploaded, setDocumentsUploaded] = useState([]);

	useEffect(() => {
		document.title = 'Customer Portal - RMA';
	}, []);

	if (!props.show) {
		return null;
	}

	const { contactForm, orderDetailsForm } = getData(props.data);

	const submitForm = async (e) => {
		e.preventDefault();
		// reset errors, to repopulate on submit
		setSubmitErrors([]);

    // get data from form, and gather errors for not filled information
		const { errors, contactInfo, partReturnInfo } =
			validateForm(e);

    // set errors in form (not filled, etc.)
		setSubmitErrors(errors);

		if (errors.length === 0) {
      		postFormData(contactInfo, partReturnInfo, documentsUploaded)
				.then((result) => {
					if (result) {
						props.closeModal();
						toast.success('Successfully submitted!');
						sessionStorage.removeItem('RMAs');
					} else {
						toast.error('Error while submitting RMA. Try again later!');
					}
				})
		}
	};

	return (
		<div style={{ display: `${props.show ? 'block' : 'none'}`}}>
			<div className="modal-background" />
			<div className="modal-container">
				<div className="modal-head">
					RMA Request Form
					<span className='modal-background-btn' onClick={props.closeModal}>X</span>
				</div>
				<div className='modal-form'>
					<div className='' style={{ 
							textAlign: 'center',
							boxShadow: '0 0 0.35rem 0rem inset #0000006b',
							padding: '1rem',
							borderRadius: '10px'
						}}>
						<span>
						We are sorry to hear that you are having issues with the components that you purchased from us. Please <strong>fully</strong> complete this form so that we can assist you accordingly.  
						Once we receive your RMA request, our claims team will begin their investigation and respond with next steps to the <strong>email that is selected</strong>.
						<br/><br/>
						This entire process generally takes <strong>7-10</strong> days to complete.
						</span>
					</div>
				</div>
				<div className="modal-body">
					<form className="modal-form" id="contact">
						<div className="modal-subhead">Contact Information</div>
						<RmaFormSection data={contactForm} />
					</form>
					<form className="modal-form" id="orderDetails">
						<div className="modal-subhead">
							RMA and Order Details
						</div>
						<RmaFormSection data={orderDetailsForm} />
					</form>
					<div className="modal-form" id="documentUpload">
						<div className="modal-subhead">Document Upload<span style={{ color: "red" }}>*</span></div>
						<div style={{ marginBottom: '15px', textAlign: 'initial' }}>
								Please attach all relevant supporting
								documentation for the RMA request such as:
								<ul>
									<li>Pictures</li>
									<li>Screenshots</li>
									<li>Test reports</li>
								</ul>
								or anything else that you would like to add.
						</div>
						<DocumentUpload documentsUploaded={documentsUploaded} setDocumentsUploaded={setDocumentsUploaded} />
					</div>
				</div>
				<div className="modal-footer">
					{submitErrors &&
						submitErrors.map((error, index) => (
							<p style={{ color: 'red', fontSize: 'small' }} key={error +'' + index}>
								{error}
							</p>
						))}
					<button className="modal-btn submit" onClick={submitForm}>
						Submit
					</button>
					<button onClick={props.closeModal} className="modal-btn close">
						Close
					</button>
				</div>
			</div>
		</div>
	);
}

export default RmaModal;
