import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SingleNavBar from "../../components/SingleNavBar";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import Loading from '../../components/Loading';
import NotFound from '../NotFound';

function SingleInvoice() {
  useEffect(() => {
    getFullInvoice();
  }, []);

  const invoiceid = useParams().invoiceid;
  let invoiceNum;
  try {
    invoiceNum = JSON.parse(sessionStorage.getItem('invoices')).filter(invoice => invoice.invoice_id === invoiceid)[0].invoice_number;
  } catch (TypeError) {
    invoiceNum = ''
  }

  useEffect(() => {
    document.title = 'Customer Portal - ' + invoiceNum;
  }, []);

  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState();
  const [pdf, setPdf] = useState();

  const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const onDocumentLoadSuccess = ({ numPages }) => {
		const pages = Array(numPages);
		for (var i = 0; i < numPages; i++) {
			pages[i] = "";
		}
		setNumPages(pages);
		setLoading(false);
	};

  if (!invoiceNum) {
    return (<NotFound />)
  }

  async function getFullInvoice() {
    const response = await fetch(`/api/invoices/${invoiceid}`, {
      method: "GET"
    });
    const data = await response.json();
    if (response.ok) {
      setInvoice(data.data.invoice);
    } else {
      // if (response.statusText === 'Unauthorized') {
			// 	Auth.reAuth()
      //   .then(() => getFullInvoice());
			// }
    }
  }

  return (<>
      {loading && 
        <Loading />}
      <div className={`single-order-container ${loading && 'hidden'}`} style={{ width: '100%', maxWidth: '1000px' }}>
      <SingleNavBar whereTo={"/invoices"} text={"Back"} data={invoice} pdf={pdf} setPdf={setPdf} />
      <div className="border pdf-view-container">
				  <Document
					  file={pdf}
					  onLoadSuccess={onDocumentLoadSuccess}
				    >
              {numPages && numPages.map((_, index) => (
                <Page 
                  key={'page'+ index + 1}
                  scale={document.getElementById('root').clientWidth / 1250 * (96/72)}
                  pageNumber={index + 1}>
							      <p className='pdf-page-num'></p>
                </Page>
              ))}
				</Document>
      </div>
    </div>
  </>);
}

export default SingleInvoice;