import {React, useEffect, useState} from 'react';
import Loading from '../../components/Loading';
import { Search } from '../../components/Search';
import { PizzaTracking } from './PizzaTracking';

const trackingFunc = () => { 
    let returnObj = {current: [], shipped: [], recent: []};
    const dd = JSON.parse(sessionStorage.getItem("dd"));
    let os = JSON.parse(sessionStorage.getItem("orders"));
    os.forEach(order => {
        try {
            if (order.packages.length === 0) {
                returnObj.current.push(order);
            } else {
                order.packages.forEach(pkg => {
                    const date = new Date(pkg.shipment_date);
                    // add to recent tracking if shipped within last 5 days
                    if ((Math.abs(new Date() - date.getTime()) / (1000 * 60 * 60 * 24)) < 5)
                    {
                        returnObj.current.push({
                            salesorder_id: order.salesorder_id, 
                            salesorder_number: order.salesorder_number,
                            date: order.date,
                            package: pkg
                        });
                    }
                })
                returnObj.shipped.push(order);
            }
            order.deal = dd.filter(d => d.so === order.salesorder_number)[0];
        } catch (TypeError) {
        }
    });
    sessionStorage.setItem("orders",JSON.stringify(os));
    return returnObj;
}

function Tracking () {
    // get tracking information
    const [activeTab, setActiveTab] = useState("1");
    const [searchText, setSearchText] = useState("");
    const loaded = JSON.parse(sessionStorage.getItem('edl'));
    const [loadingData, setLoadingData] = useState(loaded);
    const tracking = trackingFunc();
    function changeTab (tab) {
        if (tab.target.id !== "active") {
            setActiveTab(tab.target.attributes.name.nodeValue);
            if (tab.target.attributes.name.nodeValue === "1") {
                document.getElementById("tracking-slide").scrollBy({left: -1000, top: -1000, behavior: "smooth"});
            } else if (tab.target.attributes.name.nodeValue === "2") {
                document.getElementById("tracking-slide").scrollBy({left: 1000, behavior: "smooth"});
            }
        }
    }
    useEffect(() => {
        document.title = 'Customer Portal - Order Tracking';
    },[])
    useEffect(() => {
        setLoadingData(loaded);
    }, [loaded]);
    return (
        <div id="tracking-container" className="container px-1 px-md-4 py-5 mx-auto">
            <div id="t-top-container">
                <div id="t-top-tabs" className="d-flex justify-content-evenly" onClick={e => changeTab(e)}>
                    <span id={`${activeTab === "1" && "active"}`} name="1" className=''>Current & Recent</span>
                    <span id={`${activeTab === "2" && "active"}`} name="2" className=''>Completed</span>
                </div>
                <div style={{margin: "10px 5px 0", textAlign: "right"}}>
                    <Search pill={true} setSearch={setSearchText}/>
                </div>
            </div>
            {loadingData
            ? (
            <div className='d-flex' id="tracking-slide">
                <div>
                    {tracking && tracking.current.length > 0
                        ? tracking.current.map(order=>(
                            (order.salesorder_number.includes(searchText) || order.cf_customer_po.includes(searchText)) && order.deal && (
                                <PizzaTracking information={order} key={order.salesorder_id}/>
                            )
                        ))
                        : 
                            <div style={{textAlign: 'center'}}>No current or recent orders</div>
                    }
                </div>
                <div>
                    {tracking && tracking.shipped.length > 0
                        ? tracking.shipped.map(order=>(
                            (order.salesorder_number.includes(searchText) || order.cf_customer_po.includes(searchText)) && order.deal && (
                                <PizzaTracking information={order} key={order.salesorder_id}/>
                            )
                        ))
                        :
                            <div style={{textAlign: 'center'}}>No previous orders</div>
                    }
                </div>
            </div>
            ) : (
                <Loading />
            )}
        </div>
    );
}

export default Tracking;