import React from "react";
import { useNavigate } from "react-router-dom";
import { formatDate, formatPrice } from "../../utils/helpers";
import { AiOutlineFilePdf } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";

const downloadPDF = (salesorder_id, salesorder_number) => {
    toast.success("Downloading " + salesorder_number, {
        autoClose: 1400,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
    });
    axios.get(`/api/orders/${salesorder_id}/pdf`,
        {
        responseType: 'blob',
        })
        .then((data) => {
            const url = URL.createObjectURL(data.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = `DCI ${salesorder_number}.pdf`;
            link.dispatchEvent(new MouseEvent('click'));
        })
        .catch((err) => {
            toast.error("Unable to download file");
        });
}

function SingleOrderList ({order}) {
    const {
        cf_customer_po,
        salesorder_number,
        salesorder_id,
        reference_number,
        date,
        total
    } = order;

    let navigate = useNavigate(); 
    const routeChange = () => {  
        navigate(`/orders/${salesorder_id}`);
    }

    return (
        <tr className='order grid-row' id={salesorder_id}>
            <th className='' onClick={routeChange}>{salesorder_number}</th>
            <th className='' onClick={routeChange}>{cf_customer_po ? cf_customer_po : 'No PO Number'}</th>
            <th className='' onClick={routeChange}>{reference_number}</th>
            <th className='' onClick={routeChange}>{formatDate(date)}</th>
            <th className='' onClick={routeChange}>{formatPrice(total, 'en-US', order.currency_code)}</th>
            <th className='pdf' onClick={()=>downloadPDF(salesorder_id, salesorder_number)}><AiOutlineFilePdf /></th>
        </tr>
    )
};

export default SingleOrderList;