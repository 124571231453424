import React, { useEffect, useState } from 'react';
import { getTrackingAndInvoiceData } from '../../utils/Data_Grabber';
import './index.css';

// only pull the data when it's needed
// this component will load with the head
// but it should only pull the information that's needed after the initial load of data from AuthRedirect
// addiitonalData loaded variable? in sessionstorage
// if it's false or null, load the data

export default function DataLoading() {
    const eld = JSON.parse(sessionStorage.getItem('edl'));
    const [extraDataLoaded, setDataLoaded] = useState(eld);
    const [loadingStatus, setLoadingStatus] = useState(0);
    const [maxLoad, setMaxLoad] = useState(0);
    useEffect(() => {
        if (extraDataLoaded === null) {
            sessionStorage.setItem('edl',false);
        }
        if (extraDataLoaded === false) {
            getTrackingAndInvoiceData(setLoadingStatus, setMaxLoad, setDataLoaded);
        }
    },[extraDataLoaded, eld]);
        return (
            <div className="data-box" id={`data-box-${extraDataLoaded ? "hide" : "show"}`}>
                <h4>Loading Additional Data...</h4>
                <div>
                    <div id="loading-bar" style={{width: `${(loadingStatus / maxLoad) * 100}%`}}></div>
                </div>
            </div>
           );
    
}