const uploadRMA = async (rmaData) => {
    const rmaResponse = await fetch("/api/RMAs", {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ data: [rmaData] })
        });

    const data = await rmaResponse.json();
    if (rmaResponse.ok) {
        return data.data.data[0].details.id;
    } else {
        return false;
    }
}
// upload parts

// upload attachments
const uploadAttachment = async (rma_id, fileUploadInfo) => {
    fileUploadInfo.forEach(file => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        const request = new XMLHttpRequest();
    
        request.open("POST", `/api/RMAs/${rma_id}/attachments`);
        request.send(formData);
    })
}

const uploadParts = async (rma_id, {partsReturned}) => {
        fetch(`/api/RMAs/parts`, {
            method: 'post',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify({
                RMA_Record: rma_id,
                data: partsReturned
            })
        })
        .then((response) => {
            return response.json()
        })
        .then(() => {
            return true;
        })
        .catch((err) => {
            return false;
        })
}

export const postFormData = async (contactInfo, partReturnInfo, fileUploadInfo) => {
    const { Email, Contact_Name, Contact_Phone, RMA_Requesting_Account } = contactInfo;
    const { PO_Number, Invoice_Number, partsReturned } = partReturnInfo;

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; 
    var yyyy = today.getFullYear();
    if(dd<10) dd='0'+dd;
    if(mm<10) mm='0'+mm;

    const rmaJSON = {
        Email,
        RMA_Requesting_Contact: Contact_Name,
        Contact_Phone,
        RMA_Requesting_Account,
        Name: 'test',
        PO_Number,
        Invoice_Number,
        RMA_Request_Date: `${yyyy}-${mm}-${dd}`,
        RMA_Status: 'Pending'
    }
    const partsJSON = {
        partsReturned,
    }

    const response = await uploadRMA(rmaJSON)
        .then((rmaid) => {
            uploadAttachment(rmaid, fileUploadInfo)
            partsJSON.partsReturned.map(part => part.RMA_Record = rmaid);
            uploadParts(rmaid, partsJSON)
        })
        .then(() => {
            return true;
        })
        .catch((err) => {
            return false;
        })

    return response;
}