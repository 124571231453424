import React, { useState } from 'react';

function IndividualFile({ index, setOrAddFile }) {
	const [selectedFileError, setFileError] = useState('');

	const changeHandler = (event) => {
        try {
            if (event.target.files[0].size > 5000000) {
                setFileError({
                    message: 'File too large! Please select one under 5MB',
                });
            } else {
				
                setFileError('');
				setOrAddFile(event.target.files[0], index);
            }
        } catch (e) {
			setOrAddFile('', index);
            setFileError(''); 
        }
	};

	return (
		<>
			<input
				type="file"
				key={`file${index}`}
				id={`file${index}`}
				name={`file`}
				onChange={changeHandler}
				style={{ marginBottom: '5px' }}
			/>
			{selectedFileError !== '' && (
				<p style={{ color: 'Red', marginTop: 0 }}>{selectedFileError.message}</p>
			)}
		</>
	);
}

export default IndividualFile;
