import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Auth from '../../utils/auth';
import ChangePassword from '../ChangePassword';
import HiddenNav from '../HiddenNav';
import { IoHomeOutline, IoCartOutline } from 'react-icons/io5';
import { IoMdPaper } from 'react-icons/io';
import { TbFileInvoice, TbReportMoney, TbTruckReturn, TbPlaneDeparture, TbZoomQuestion, TbForms } from 'react-icons/tb';
import { MdOutlineFeedback, MdOutlineSubscriptions } from 'react-icons/md';
import NeedHelp from '../../pages/NeedHelp';

const user = JSON.parse(sessionStorage.getItem('user'));
const account = JSON.parse(sessionStorage.getItem('account'));
let first_name, last_name, email, phone;
let billing_address, company_name;
user && (
{ first_name, last_name, email, phone } = user
);
account && (
	{ billing_address, company_name } = account
)


function SideNav() {
	let navigate = useNavigate();
	const routeChange = (event) => {
		const route =
			event.target.id !== ''
				? event.target.id
				: event.target.parentElement.id;
		navigate(`${route}`);
		setShowNav(false);
	};

	const [showNav, setShowNav] = useState(false);
	const [modalOpen, setModalOpen] = useState('false');
	const [showHelp, setHelp] = useState(false);
	
	const navItems = {
		show: ['Home', 'Tracking', 'Sales Orders', 'Invoices', 'Payments', 'RMAs', 'Statements'],
		url: ['/home', '/tracking', '/orders', '/invoices', '/payments', '/RMAs', '/statements'],
		icon: [<IoHomeOutline />, <TbPlaneDeparture />, <IoCartOutline />, <TbFileInvoice />, <TbReportMoney />, <TbTruckReturn />, <IoMdPaper />]
	};

	return (<>	
		<HiddenNav setShowNav={setShowNav} showNav={showNav} />
		<ChangePassword modalOpen={modalOpen} setModalOpen={setModalOpen} />

		<div className={`side-nav-container ${showNav && 'show-nav'}`}>
			{/* Top section of nav */}
			<div className="bottom-border">
				{/* Photo, name and email */}
				<div className="user-information">
					<div style={{ overflow: 'hidden', lineHeight: .50, textAlign: 'center', margin: 'auto' }}>
						<div className="">
							<h2 className='side-name' style={{ wordBreak: 'break-all' }}>
								<span style={{ paddingRight: '5px' }}>{first_name}</span> <span>{last_name}</span>
							</h2>
						</div>
						<p className='side-email' style={{ fontSize: 'small', color: 'rgba(255, 255, 255, 0.611)' }}>{Auth.getProfile().email}</p>
					</div>
				</div>
				{/* Log out and Change password*/}
				<div className='flex' style={{ justifyContent: 'space-between', padding: '0 15px' }}>
					<div 
						className='link' 
						onClick={(e) => Auth.logout()}
						style={{ paddingBottom: '10px' }}
						>
						<span className='logout-change'>Log Out</span>
					</div>
					<div className='link' onClick={(e) => setModalOpen('true')}
						style={{ paddingBottom: '10px' }}>
						<span className='logout-change'>Change Password</span>
					</div>
				</div>
			</div>

			{/* Navigation tabs */}
			<div className="side-nav-category bottom-border">
				{navItems.show.map((item, index) => (
					<div
						className={`side-nav-button link ${
							window.location.pathname.includes(navItems.url[index]) ? 'active' : ''
						}`}
						id={navItems.url[index]}
                        key={navItems.url[index]}
						onClick={routeChange}
					>
						<span style={{ padding: '10px', paddingLeft: '15px' }}>{navItems.icon[index]} {item}</span>
					</div>
				))}
			</div>
			<div className="side-nav-bar-footer">
				<span><a href="https://directics.com/faq/" target="_blank" rel="noreferrer">- FAQ</a></span>
				<span><a href="https://directics.com/customer-portal-survey/" target="_blank" rel="noreferrer">- Provide Feedback</a></span>
				{(user && account) 
				?
					<span><a href={`https://directics.com/submit-rfq?lSource=DCI%20Website&first=${first_name}&last=${last_name}&email=${email}&phone=${phone === null || phone === "" ? "" : phone}&country=${billing_address.country === null || billing_address.country === "" ? "" : billing_address.country}&company=${company_name}`} target="_blank" rel="noreferrer">- Request Parts</a></span>
				:
					<span><a href={`https://directics.com/submit-rfq`} target="_blank" rel="noreferrer">- Request Parts</a></span>
				}
				
				<span>
					<NeedHelp showHelp={showHelp} setHelp={setHelp} />
					<span onClick={(e) => setHelp(!showHelp)} style={{color: (showHelp ? "white" : 'rgba(255, 255, 255, 0.61)')}}>- Portal Instructions</span>
				</span>
			</div>
		</div>
	</>);
}

export default SideNav;
