import React, { useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { CgArrowsExpandDownLeft } from 'react-icons/cg';
import ExtraInformation from '../ExtraInformation';

function IndividualItemRMARow({
	part,
	last,
	addRows,
	setSelected,
}) {
	const [valueReturn, setValueReturn] = useState(0);
	const [dateCode, setDateCode] = useState('');
	const [expandedInfo, setExpandedInfo] = useState(false);

	const handleValueChange = (e) => {
		if (e.target.value > part.quantity) {
			setValueReturn(part.quantity);
		} else if (e.target.value < 0) {
			setValueReturn(0);
		} else {
			setValueReturn(e.target.value);
		}

		if (e.target.value == 0 || e.target.value === '') {
		setSelected(false);
		} else {
		setSelected(true);
		}
	}

	const handleExtraInfoClose = (e) => {
		e.preventDefault();
		setExpandedInfo(!expandedInfo);
	}

	return (
		<>
		<table>
		<tbody>
		<tr>
			<td colSpan={1}>
				<input
					type="number"
					value={valueReturn}
					onChange={handleValueChange}
					className="part-amt-box"
					max={part.quantity}
					min={0}
					id="RMA_Quantity"
				/>
				{part.unit}
			</td>

			<td colSpan={1}>
				<input
					value={dateCode}
					onChange={(e) => { 
						setDateCode(e.target.value); 
						setExpandedInfo(true) 
					}}
					type="text"
					className="part-amt-box"
					id="Date_Code_Lot"
				/>
			</td>

			<td
				className={`additional-info-btn ${expandedInfo && 'expanded'}`}
				onClick={handleExtraInfoClose}
			>
				<CgArrowsExpandDownLeft /> Additional Information{' '}
				<span style={{ color: 'red' }}>*</span>
			</td>

			
				<td style={{ width: '35px' }}>
					{last && (
					<FiPlus className="plus-icon" onClick={addRows} />
					)}
				</td>
		</tr>
		</tbody>
		</table>

		<div
			className={`extra-info-wrapper extra-info-shown`}
			style={{ top: (!expandedInfo ? -999 : ''), position: (!expandedInfo ? 'absolute' : '') }}>
			<table>
			<tbody>
				<ExtraInformation />
			</tbody>
			</table>
			<div style={{ textAlign: 'center' }}>
				<button 
					className='modal-btn close'
					onClick={handleExtraInfoClose}>
					Close
				</button>
			</div>
		</div>

		</>
	);
}

export default IndividualItemRMARow;
