import React from 'react';

function NotFound () {
    return (
        <div id='not-found-container'>
            <h2>404</h2>
            <p>Page not found!</p>
        </div>
    )
}

export default NotFound;